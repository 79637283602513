// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// components
import { FieldType, validationStringToMethod } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { useGetCareCircleQuery } from 'store/api/careCircle';
import { useGetCarePlanQuery } from 'store/api/carePlan';
import {
  useGetAllAnswerQuery,
  useGetAllCategoryQuery,
} from 'store/api/careProfile';
import { getCurrentCareCircleId } from 'store/slices/careCircleSlice';

// models
import {
  CareCircleMember,
  CareProfileAnswer,
  CareProfileQuestion,
  CareProfileSubCategory,
  Element,
  Form,
  isSubQuestionRelevant,
  listAllQuestionsOfSubCategory,
  Question,
  questionArrayToMap,
  Section,
} from '@karehero/models';

interface UseCareProfileOptions {
  categoryID?: string;
}

export const useCareProfile = (options?: UseCareProfileOptions) => {
  // options
  const { categoryID } = options || {};

  // state
  const [careProfileSubCategories, setCareProfileSubCategories] = useState<
    CareProfileSubCategory[]
  >([]);
  const [answers, setAnswers] = useState<any>({});
  const [careCircleMember, setCareCircleMember] = useState<CareCircleMember>();

  // hooks
  const { careProfileFormId } = useParams();
  const careCircleId = useSelector(getCurrentCareCircleId);
  const { data: careCircle } = useGetCareCircleQuery(careCircleId, {
    skip: !careCircleId,
  });

  const { data: careProfileAnswersObj } = useGetAllAnswerQuery(
    careCircle?.careProfile?.id || '',
    { skip: !careCircle?.careProfile?.id },
  );
  const careProfileAnswers = useMemo(
    () => careProfileAnswersObj && Object.values(careProfileAnswersObj),
    [careProfileAnswersObj],
  );

  const { data: careProfileCategories } = useGetAllCategoryQuery();
  const { data: carePlan } = useGetCarePlanQuery(careCircle?.id || '', {
    pollingInterval: 10 * 60 * 1000,
    skip: !careCircle?.id,
  });

  // memos
  const category = useMemo(() => {
    return careProfileCategories?.find(
      (category) => category.id === (categoryID || 'care-plan'),
    );
  }, [careProfileCategories, categoryID]);

  const subCategory = useMemo(() => {
    setCareProfileSubCategories(category?.careProfileSubCategories || []);
    return category?.careProfileSubCategories?.[0];
  }, [category]);

  const subQuestions = useMemo(
    () => subCategory && listAllQuestionsOfSubCategory(subCategory),
    [subCategory],
  );

  const questionMap = useMemo(
    () => subQuestions && questionArrayToMap(subQuestions),
    [subQuestions],
  );

  const answersByQuestionId: { [key: string]: CareProfileAnswer } =
    useMemo(() => {
      return (
        careProfileAnswers?.reduce((acc, answer: CareProfileAnswer) => {
          return { ...acc, [answer.careProfileQuestionId]: answer };
        }, {}) || {}
      );
    }, [careProfileAnswers]);

  const isQuestionAnswered = useCallback(
    (question: CareProfileQuestion): boolean => {
      const answer = answersByQuestionId[question.id]?.value;

      return answer !== undefined && answer !== '';
    },
    [answersByQuestionId],
  );

  const isQuestionRelevant = useCallback(
    (q: CareProfileQuestion): boolean => {
      if (!questionMap || !answersByQuestionId) return true;

      const relevanceChecker = (question: CareProfileQuestion): boolean => {
        if (!question.parentId || question.parentId === '') {
          return true;
        }

        const parentQuestion = questionMap[question.parentId];

        if (!relevanceChecker(parentQuestion)) {
          return false;
        }

        if (!isQuestionAnswered(parentQuestion)) {
          return true;
        }

        const parentAnswer = answersByQuestionId[question.parentId]?.value;

        return isSubQuestionRelevant(question, parentAnswer);
      };

      return relevanceChecker(q);
    },
    [questionMap, answersByQuestionId, isQuestionAnswered],
  );

  const forms = useMemo(() => {
    if (!category) return [];

    return category.careProfileSubCategories
      .map((subCategory) => {
        return subCategory.careProfileForms.map((form: any) => {
          let order = 0;
          let fields: any = {};

          const assignField = (question: CareProfileQuestion) => {
            let table = {};
            if (question.fieldTable) {
              const splitTable = question.fieldTable?.split(';');
              const title = splitTable[0];
              const columns = splitTable[1].split(',');
              const rows = splitTable[2].split(',');
              table = {
                title: title,
                columns: columns,
                rows: rows,
              };
            }

            order += 10;

            const mapField = (question: CareProfileQuestion) => {
              return {
                id: question.id,
                label: question.label.replace(
                  '{careRecipient}',
                  careCircle?.careRecipientAccount?.firstName ||
                    'your loved one',
                ),
                isRequired: question.isRequired,
                placeholder: question.placeholder,
                tooltip: question.tooltip,
                validation: validationStringToMethod(
                  question.validation as string,
                ),
                field: {
                  type: question.fieldType,
                  options: question.fieldOptions
                    ?.map((option: any) => ({
                      value: option.id,
                      label: option.label,
                      order: option.order,
                    }))
                    .sort((a: any, b: any) => a.label.localeCompare(b.label)),
                  table: table,
                },
              };
            };

            const mappedField = mapField(question);

            fields[question.id] = {
              ...mappedField,
              order,
              field: {
                ...mappedField.field,
                subfields:
                  question.fieldType === FieldType.QuestionTable
                    ? question.careProfileSubQuestions?.map((subq: any) =>
                        mapField(subq),
                      )
                    : undefined,
              },
            };
          };
          const questions = [...(form.careProfileQuestions || [])];
          questions.sort((a, b) => a.order - b.order);
          questions.forEach((question: any) => {
            assignField(question);

            const answer = answers?.[form.id]?.[question.id];
            const createSubQuestions = (question: any, answer: any) => {
              if (question.fieldType === FieldType.QuestionTable) return;

              if (answer !== undefined) {
                if (answer === true) answer = 'true';
                if (answer === false) answer = 'false';

                const subQuestions = [
                  ...(question.careProfileSubQuestions || []),
                ];
                subQuestions.sort((a: any, b: any) => a.order - b.order);
                subQuestions.forEach((subQuestion: any) => {
                  let conditionValue = subQuestion.conditionValue;

                  switch (subQuestion.conditionOperator) {
                    case 'equal':
                      if (answer === conditionValue) break;
                      return;
                    case 'not-equal':
                      if (answer !== conditionValue) break;
                      return;
                    case 'contains':
                      if (answer.includes(conditionValue)) break;
                      return;
                    case 'not-contains':
                      if (answer.includes(conditionValue)) break;
                      return;
                    case 'greater-than':
                      if (conditionValue === undefined) break;
                      if (answer > conditionValue) break;
                      return;
                    case 'less-than':
                      if (conditionValue === undefined) break;
                      if (answer < conditionValue) break;
                      return;
                    case 'greater-than-or-equal':
                      if (conditionValue === undefined) break;
                      if (answer >= conditionValue) break;
                      return;
                    case 'less-than-or-equal':
                      if (conditionValue === undefined) break;
                      if (answer <= conditionValue) break;
                      return;
                  }

                  assignField(subQuestion);

                  const subQuestionAnswer =
                    answers?.[form.id]?.[subQuestion.id];
                  if (subQuestionAnswer !== undefined) {
                    createSubQuestions(subQuestion, subQuestionAnswer);
                  }
                });
              }
            };

            createSubQuestions(question, answer);
          });

          return {
            id: form.id,
            title: form.title,
            description: form.description,
            isComplete: form.careProfileQuestions?.every(
              (question: any) =>
                (answers?.[form.id]?.[question.id] !== undefined ||
                  question.isRequired === false) &&
                answers?.[form.id]?.[question.id] !== '',
            ),
            fields: fields,
          };
        });
      })
      .flat();
  }, [category, careCircle, answers]);

  const numCompleted = useMemo(() => {
    return subQuestions
      ? subQuestions.filter((q: CareProfileQuestion) => {
          return (
            !q.isRequired || isQuestionAnswered(q) || !isQuestionRelevant(q)
          );
        }).length
      : 0;
  }, [subQuestions, isQuestionAnswered, isQuestionRelevant]);

  const sections: Section[] = useMemo(() => {
    if (!careProfileSubCategories || careProfileSubCategories.length === 0) {
      return [];
    }

    const isFormInProgress = (form: Form): boolean => {
      return form.careProfileQuestions.some(
        (question: Question) => answersByQuestionId[question.id],
      );
    };

    const isFormComplete = (form: Form): boolean => {
      return form.careProfileQuestions.every(
        (question: Question) => answersByQuestionId[question.id],
      );
    };

    return careProfileSubCategories.map((element: Element) => {
      const subSections = element.careProfileForms.map((form: Form) => ({
        title: form.title.replace(
          '{careRecipient}',
          careCircle?.careRecipientAccount?.firstName || 'your loved one',
        ),
        id: form.id,
        isComplete: isFormInProgress(form),
        to: `/${element.careProfileCategoryId}/${form.id}`,
      }));

      return {
        title: element.title.replace(
          '{careRecipient}',
          careCircle?.careRecipientAccount?.firstName || 'your loved one',
        ),
        id: element.id,
        subSections,
        sectionStatus: element.careProfileForms.every(isFormComplete)
          ? 'complete'
          : element.careProfileForms.some(isFormInProgress)
            ? 'inProgress'
            : 'notStarted',
      };
    });
  }, [careProfileSubCategories, answersByQuestionId, careCircle]);

  const numTotal = useMemo(() => {
    return subQuestions?.length || 0;
  }, [subQuestions]);

  // effects
  useEffect(() => {
    if (!careProfileAnswers) return;

    const careAnswers: any = {};
    careProfileAnswers.forEach((answer) => {
      let forms = answer?.careProfileQuestion?.careProfileForms;
      if (!forms) {
        // @ts-ignore
        const parentId = answer?.careProfileQuestion?.parentId;
        const careProfileAnswersParent = careProfileAnswers.find(
          (answer) => answer.careProfileQuestionId === parentId,
        );
        if (!careProfileAnswersParent) return;
        forms = careProfileAnswersParent?.careProfileQuestion?.careProfileForms;
        if (!forms) return;
      }
      forms.forEach((form) => {
        if (!careAnswers[form.id]) {
          careAnswers[form.id] = {};
        }
      });

      forms.forEach((form) => {
        careAnswers[form.id][answer.careProfileQuestionId] = answer.value;
      });
    });

    setAnswers?.(careAnswers);
  }, [careProfileAnswers, setAnswers]);

  useEffect(() => {
    if (!careCircle) return;
    const member = careCircle?.careCircleMembers?.find(
      (member) => member.account.id === careCircle?.primaryCaregiverAccount.id,
    );
    setCareCircleMember(member);
  }, [careCircle]);

  return {
    answers,
    setAnswers,
    careProfileCategories,
    category,
    subCategory,
    forms,
    numCompleted,
    numTotal,
    careCircle,
    careProfileFormId,
    carePlan,
    careCircleMember,
    sections,
  };
};
