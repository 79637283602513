import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
// components
import { Button, Container, Icon, LottieAnimation } from "../../atoms";
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
// assets
// @transform-path ../../../../../../src/assets/animation/confetti.json
import confettiAnimation from "../../../../../../src/assets/animation/confetti.json";
const animationDuration = 1000;
export const OnboardingLoadingDone = toGenericOnboarding(({ config, currentIndex = 0, handleClick, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return (_jsx(StyledContainer, Object.assign({ size: 'sm', color: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.color }, { children: _jsxs(Wrapper, { children: [((_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.confetti) && (_jsx(AnimationContainer, { children: _jsx(LottieAnimation, { animationData: confettiAnimation, loop: true, autoplay: true }) })), _jsxs(ContentWrapper, Object.assign({ "$isVisible": true }, { children: [_jsx(ImageWrapper, Object.assign({ "$bgColor": (_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.color }, { children: _jsx(Icon, { fontSize: 40, icon: (_d = config[currentIndex]) === null || _d === void 0 ? void 0 : _d.icon }) })), _jsx(Text, { children: (_e = config[currentIndex]) === null || _e === void 0 ? void 0 : _e.text }), ((_f = config[currentIndex]) === null || _f === void 0 ? void 0 : _f.subtext) && (_jsx(SubText, { children: (_g = config[currentIndex]) === null || _g === void 0 ? void 0 : _g.subtext })), _jsx(ButtonWrapper, Object.assign({ "$isVisible": true }, { children: _jsx(Button, Object.assign({ size: 'md', isFullWidth: true, ariaLabel: (_h = config[currentIndex]) === null || _h === void 0 ? void 0 : _h.ctaButtonLabel, onPress: () => handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next) }, { children: (_j = config[currentIndex]) === null || _j === void 0 ? void 0 : _j.ctaButtonLabel })) }))] }))] }) })));
});
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[32]};
  padding: ${(props) => props.theme.spacing[32]} 0;
  ${(props) => !props.$isVisible &&
    css `
      opacity: 0;
      transform: scale(0.8);
    `}
  transition-duration: ${animationDuration / 2}ms;
`;
const ButtonWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing[32]};
  ${(props) => !props.$isVisible &&
    css `
      opacity: 0;
      transform: scale(0.8);
    `}
  transition-duration: ${animationDuration / 2}ms;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[24]};
`;
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.cards.yellow.secondary};
  transition-duration: ${animationDuration}ms;
  height: unset;
  flex: 1;
`;
const AnimationContainer = styled.div `
  transform: scale(2);
  position: fixed;
  pointer-events: none;
`;
const ImageWrapper = styled.div `
  width: 72px;
  height: 72px;
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
  border-radius: 999999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.cards.yellow.secondary};
  background: ${(props) => { var _a; return (_a = props.theme.color.cards[props.$bgColor]) === null || _a === void 0 ? void 0 : _a.tertiary; }};
  padding: ${(props) => props.theme.spacing[12]};
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.lg.semibold};
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: center;
`;
const SubText = styled(Text) `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: center;
`;
OnboardingLoadingDone.displayName = 'OnboardingLoadingDone';
export default toGenericOnboarding(OnboardingLoadingDone);
