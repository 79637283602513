export const color = {
    primary: {
        10: '#fce9e5',
        20: '#f4a689',
        30: '#f18b6c',
        40: '#d67c61',
        50: '#d67c61',
        60: '#f4a689',
        70: '#d67c61',
        80: '#003413',
        90: '#000000',
        100: '#000000',
    },
    danger: {
        10: '#ebe5e7',
        20: '#ffe8e8',
        30: '#f9c2c5',
        40: '#e7294b',
        50: '#e7294b',
        60: '#af000b',
    },
    warning: {
        10: '#eae3de',
        20: '#fff3e9',
        30: '#ffdec7',
        40: '#e89a51',
        50: '#d16603',
        60: '#b15600',
    },
    success: {
        10: '#ccd8d0',
        20: '#99b5a2',
        30: '#668a73',
        40: '#335f43',
        50: '#003413',
        60: '#002f11',
    },
    info: {
        10: '#ecf2fc',
        20: '#dce1ea',
        30: '#bfd3f8',
        40: '#3081fb',
        50: '#044dba',
        60: '#043c8f',
    },
    neutral: {
        10: '#ffffff',
        20: '#faf7f3',
        30: '#f2ede3',
        40: '#cbc5b9',
        50: '#d9d9d9',
        60: '#b2b2b2',
        70: '#8c8c8d',
        80: '#666667',
        90: '#404042',
        100: '#000000',
    },
    cards: {
        blue: {
            primary: '#f2ede3',
            secondary: '#fdfbf8',
            tertiary: '#cbc5b9',
        },
        orange: {
            primary: '#f2ede3',
            secondary: '#fdfbf8',
            tertiary: '#c9ac73',
        },
        green: {
            primary: '#99b5a2',
            secondary: '#ccd8d0',
            tertiary: '#003413',
        },
        red: {
            primary: '#fad7c8',
            secondary: '#fce9e5',
            tertiary: '#f18b6c',
        },
        yellow: {
            primary: '#ebefd4',
            secondary: '#f4f7e6',
            tertiary: '#cfe09b',
        },
        lime: {
            primary: '#ebefd4',
            secondary: '#f4f7e6',
            tertiary: '#b5c557',
        },
        navDrawer: {
            primary: '#cfe09b',
            secondary: '#ebefd4',
        },
    },
    actionPlanCards: {
        grey: {
            primary: '#404042',
            secondary: '#f2ede3',
        },
        green: {
            primary: '#003413',
            secondary: '#cfe09b',
        },
        red: {
            primary: '#ffffff',
            secondary: '#f18b6c',
        },
        yellow: {
            primary: '#d16603',
            secondary: '#ffdec7',
        },
        blue: {
            primary: '#044dba',
            secondary: '#eae8f8',
        },
        purple: {
            primary: '#813c8d',
            secondary: '#f8defd',
        },
    },
    surfaces: {
        white: '#ffffff',
        offWhite: '#fdfdfd',
        dustySand: '#f2ede3',
        floralWhite: '#f2ede3',
        papayaWhip: '#f2ede3',
        mistyRose: '#f2ede3',
        pastelLavender: '#cfe09b',
        pastelThistle: '#cfe09b',
        lightPlum: '#003413',
        mediumPurple: '#003413',
        lavenderBlue: '#003413',
    },
    accent: {
        moccasin: '#cfe09b',
        violetLight: '#f18b6c',
        darkPurple: '#003413',
    },
    illustration: {
        eggBlue: '#f2ede3',
        eggBlueLight: '#f2ede3',
        steelBlue: '#f2ede3',
        plum: '#003413',
        lightCyan: '#edf1e3',
        mediumCyan: '#dee8bf',
        aquamarine: '#d7e4ad',
        mediumAquamarine: '#cfe09b',
        pink: '#f3b5a3',
        mediumPink: '#f2a087',
        peachPuff: '#f18b6c',
    },
    custom: {
        calendarCell: '#faf7f3',
        tableToolbar: '#f4f0e8',
        navDrawerBlue: '#cfe09b',
        navDrawerPurple: '#f18b6c',
        fadeUp: 'linear-gradient(180deg, #cfe09b 0%, #003413 96.88%)',
        fadeDown: 'linear-gradient(180deg, #003413 0%, #cfe09b 96.88%)',
        ai: 'linear-gradient(264deg, #ed854a -3.56%, #e14363 44.62%, #a94de1 98.09%)',
        logoBackground: '#cedf9a',
    },
    overlay: 'rgba(0, 0, 0, 0.5)',
};
export default color;
