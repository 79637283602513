// react
import { useEffect } from 'react';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// components
import { Calendly as BookCallPage } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';

/**
 * BookCall shows the BookCallPage component.
 */
const BookCall = () => {
  // hooks
  const account = useSelector(getAccount);
  const { track } = useMixpanel();

  // effects
  useEffect(() => {
    track(MixpanelEvent.BookCallViewed, {});
  }, [track]);

  return (
    <BookCallPage name={`${account.firstName || ''}`} email={account.email} />
  );
};

export default BookCall;
