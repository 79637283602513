import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// components
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { Button, Card, Container, Icon, } from "../../atoms";
// assets
import { css } from 'styled-components';
const animationBase = 300;
/**
 * OnboardingKeni renders the Keni screen for onboarding flows.
 */
export const OnboardingKeni = toGenericOnboarding(({ config, currentIndex = 0, handleClick, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    // state
    const [isWaiting, setIsWaiting] = useState(false);
    // effects
    useEffect(() => {
        setIsWaiting(true);
        const timerId = setTimeout(() => {
            setIsWaiting(false);
        }, animationBase * 3);
        return () => {
            setIsWaiting(false);
            clearTimeout(timerId);
        };
    }, [currentIndex]);
    return (_jsx(Background, { children: _jsx(StyledContainer, { children: _jsxs(StyledOnboardingKeni, { children: [_jsxs(Wrapper, { children: [((_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title) && (_jsx(Title, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.title })), ((_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.subTitle) && (_jsx(SubTitle, { children: (_d = config[currentIndex]) === null || _d === void 0 ? void 0 : _d.subTitle }))] }), _jsx(Content, Object.assign({ "$isShowing": !!config[currentIndex].options, "$speed": animationBase }, { children: config[currentIndex].options && (_jsx(RadioWrapper, { children: (_e = config[currentIndex].options) === null || _e === void 0 ? void 0 : _e.map((option) => (_jsxs(Radio, Object.assign({ to: option.to, onClick: () => {
                                    handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.End);
                                } }, { children: [_jsx(TextWrapper, { children: option.text }), _jsx(Icon, { icon: 'exclamation-circle', fontSize: 16 })] })))) })) })), _jsx(Card, Object.assign({ color: 'green' }, { children: _jsx(StyledText, { children: "Head over to your work email inbox!" }) })), ((_f = config[currentIndex]) === null || _f === void 0 ? void 0 : _f.buttonText) && (_jsx(ButtonWrapper, { children: _jsx(StyledButton, Object.assign({ ariaLabel: 'get started', isDisabled: isWaiting, onPress: () => {
                                handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next);
                            } }, { children: (_g = config[currentIndex]) === null || _g === void 0 ? void 0 : _g.buttonText })) }))] }) }) }));
});
const StyledContainer = styled(Container) `
  height: 100%;
`;
const StyledOnboardingKeni = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  padding: ${(props) => props.theme.spacing[24]};
  padding-bottom: 0;
`;
const Background = styled.div `
  height: 100%;
  background: ${(props) => props.theme.color.neutral[20]};
`;
const Content = styled.div `
  width: 100%;
  min-height: 0;
  flex: 0.00000000000000000001;
  opacity: 0;
  overflow: hidden;
  padding: ${(props) => props.theme.spacing[12]};
  overflow-y: auto;

  transition: ${(props) => css `
    opacity ${props.$speed}ms ease ${props.$speed * 3}ms,
    flex ${props.$speed * 2}ms ease ${props.$speed}ms;
  `}
    ${(props) => props.$isShowing &&
    css `
        opacity: 1;
        flex: 4;
        ${props.theme.breakpoint.sm(css `
          flex: 2;
        `)}
      `};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.primary[80]};
  min-height: 60px;
  text-align: center;
  ${(props) => props.theme.breakpoint.md(css `
      min-height: none;
      font: ${props.theme.typography.heading['6xl']};
      text-align: left;
    `)};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[8]};
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['3xl']};
    `)}
`;
const RadioWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  margin-bottom: ${(props) => props.theme.spacing[48]};
  overflow: visible;

  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Radio = styled(Link) `
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  background: ${(props) => props.theme.color.neutral[10]};
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  border-radius: 8px;
  width: 100%;
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.sm.medium};
  padding: ${(props) => props.theme.spacing[16]}
  elevation: ${(props) => props.theme.elevation[1]};
  box-sizing: border-box;
  cursor: pointer;

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.body.md.medium};
      padding: ${props.theme.spacing[32]} ${props.theme.spacing[24]};
    `)}

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.02);
  }
`;
const TextWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const ButtonWrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
`;
const StyledButton = styled(Button) `
  width: 400px;
  max-width: 400px;
`;
const StyledText = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: center;
`;
OnboardingKeni.displayName = 'OnboardingKeni';
export default toGenericOnboarding(OnboardingKeni);
