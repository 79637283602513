import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Container } from "../../atoms/Container/Container";
import { Logo } from "../../atoms/Logo/Logo";
import { Button, Title } from "../../atoms";
/**
 * SignIn is the page where the user can sign in.
 */
export const SignIn = ({ onSubmit }) => {
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Logo, { size: 'lg' }), _jsxs(ContentWrapper, { children: [_jsx(StyledTitle, { children: "Care doesn\u2019t have to be confusing" }), _jsx(SubTitle, { children: "Turn the chaos of caregiving into a simple stress-free journey" })] }), _jsxs(ButtonContainer, { children: [_jsx(Button, Object.assign({ ariaLabel: 'sign in', variant: 'primary', onPress: onSubmit, isFullWidth: true }, { children: "Sign in" })), _jsx(Button, Object.assign({ variant: 'link', ariaLabel: 'register', isFullWidth: true, to: '/register' }, { children: "I don't have an account" }))] })] }) })));
};
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.neutral[20]};
  overflow-y: auto;
  height: 100%;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[24]};
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[32]} 0;
`;
const StyledTitle = styled(Title) `
  text-align: center;
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[24]};
  text-align: center;
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[24]};
  margin-bottom: ${(props) => props.theme.spacing[64]};
`;
SignIn.displayName = 'SignIn';
export default SignIn;
