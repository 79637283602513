import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// react
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
// components
import { Card, Container, Icon } from "../../atoms";
import { CardContainer } from "../../templates";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
/**
 * WelcomeActions give the user options on how to proceed.
 */
export const WelcomeActions = ({ firstName, toManageCareCircles, toManageAccounts, toManageOrganizations, toManageActionPlan, }) => {
    // hooks
    const [width] = useWindowSize();
    return (_jsx(Container, { children: _jsx(Wrapper, { children: _jsxs(ContentContainer, { children: [_jsxs("div", { children: [_jsxs(GreetingTitle, { children: ["Hi ", firstName, "!"] }), _jsx(GreetingText, { children: "What would you like to manage?" })] }), _jsxs(CardContainer, Object.assign({ maxColumns: width < theme.breakpoint.smValue ? 1 : 2, size: width < theme.breakpoint.smValue ? 'sm' : 'md' }, { children: [toManageAccounts && (_jsx(StyledLink, Object.assign({ to: toManageAccounts }, { children: _jsxs(StyledCard, { children: [_jsx(Icon, { icon: 'user' }), _jsx("div", { children: "Accounts" })] }) }))), toManageOrganizations && (_jsx(StyledLink, Object.assign({ to: toManageOrganizations }, { children: _jsxs(StyledCard, { children: [_jsx(Icon, { icon: 'building' }), _jsx("div", { children: "Organizations" })] }) }))), toManageCareCircles && (_jsx(StyledLink, Object.assign({ to: toManageCareCircles }, { children: _jsxs(StyledCard, { children: [_jsx(Icon, { icon: 'circle-heart' }), _jsx("div", { children: "Care Circles" })] }) }))), toManageActionPlan && (_jsx(StyledLink, Object.assign({ to: toManageActionPlan }, { children: _jsxs(StyledCard, { children: [_jsx(Icon, { icon: 'clipboard-list' }), _jsx("div", { children: "Action Plan" })] }) })))] }))] }) }) }));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[24]} 0;
  ${(props) => props.theme.breakpoint.sm(css `
      padding: ${props.theme.spacing[64]} 0;
    `)}
`;
const ContentContainer = styled.div `
  padding: 0 ${(props) => props.theme.spacing[12]};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 650px;
  gap: ${(props) => props.theme.spacing[32]};
`;
const GreetingTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.primary[80]};
  margin-bottom: ${(props) => props.theme.spacing[4]};
  text-align: center;
`;
const GreetingText = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  text-align: center;
  color: ${(props) => props.theme.color.neutral[80]};
`;
const StyledCard = styled(Card) `
  text-align: center;
  font: ${(props) => props.theme.typography.body.md.regular};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledLink = styled(Link) `
  text-decoration: none;
  transition-duration: 0.2s;
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
`;
WelcomeActions.displayName = 'WelcomeActions';
export default WelcomeActions;
