import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
// theme
import { theme } from "../../../theme";
/**
 * Calendly is a component that displays a Hubspot contact form.
 */
export const Calendly = ({ backgroundColor, isHideGdprBanner = false, primaryColor = theme.color.primary[30], textColor = theme.color.cards.green.tertiary, name, email, }) => {
    // memos
    const dataURL = useMemo(() => {
        // initialize URL
        const calendlyURL = new URL('https://calendly.com/care-concierge-kh/care-concierge-initial-call');
        // set params
        const params = {
            text_color: textColor.replace('#', ''),
            primary_color: primaryColor.replace('#', ''),
        };
        if (backgroundColor) {
            params['background_color'] = backgroundColor.replace('#', '');
        }
        if (isHideGdprBanner)
            params['hide_gdpr_banner'] = '0';
        if (name)
            params['name'] = name;
        if (email)
            params['email'] = email;
        // construct URL
        const queryParams = new URLSearchParams(Object.assign({}, params));
        calendlyURL.search = queryParams.toString();
        return calendlyURL.toString();
    }, [backgroundColor, isHideGdprBanner, textColor, primaryColor]);
    // effects
    useEffect(() => {
        const calendlyScript = document.createElement('script');
        calendlyScript.src =
            'https://assets.calendly.com/assets/external/widget.js';
        calendlyScript.type = 'text/javascript';
        calendlyScript.async = true;
        document.body.appendChild(calendlyScript);
    }, []);
    const isCalendlyEvent = useCallback((e) => e.origin === 'https://calendly.com' &&
        e.data.event &&
        e.data.event.indexOf('calendly.') === 0, []);
    useEffect(() => {
        const listener = (e) => {
            if (!isCalendlyEvent(e))
                return;
        };
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        };
    }, [isCalendlyEvent]);
    return (_jsx(Wrapper, { children: _jsx(StyledCalendly, { className: 'calendly-inline-widget', "data-url": dataURL }) }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;
const StyledCalendly = styled.div `
  min-height: 320px;
  flex: 1;
`;
Calendly.displayName = 'Calendly';
export default Calendly;
