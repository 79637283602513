var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useState } from 'react';
import styled from 'styled-components';
// components
import { Title } from "../../atoms/Title/Title";
import { Button, Dialog } from "../../atoms";
import { CareCircle } from "../../molecules";
import { CareCircleInvite } from "../../organisms";
/**
 * CareCircleMemberAll is the page for the Care Circle.
 */
export const CareCircleMemberAll = ({ careCircle, onPreview, onInvite, relationshipOptions, }) => {
    var _a, _b, _c;
    // state
    const [isInviteOpen, setIsInviteOpen] = useState(false);
    // methods
    const handleInvite = useCallback((careCircleMemberInvite) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield onInvite(careCircleMemberInvite);
            if (!res || 'error' in res)
                return;
            setIsInviteOpen(false);
        }
        catch (e) {
            console.error(e);
        }
    }), [onInvite]);
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [_jsx(BackButton, { children: _jsx(Button, Object.assign({ ariaLabel: 'Back', variant: 'secondary', iconLeft: 'chevron-left', onPress: () => window.history.back() }, { children: "Back" })) }), _jsx(Header, { children: _jsx(Title, { children: "Care Circle" }) }), _jsx(Text, { children: "Your network of family or friends who also care for your loved one." }), _jsx(CareCircle, { onAdd: () => setIsInviteOpen(true), onPreview: onPreview, careRecipientMember: {
                            id: ((_a = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount) === null || _a === void 0 ? void 0 : _a.id) || 'id',
                            account: Object.assign({}, careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount),
                            fallback: `${((_b = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount.firstName) === null || _b === void 0 ? void 0 : _b[0]) || ''}`,
                        }, members: ((_c = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careCircleMembers) === null || _c === void 0 ? void 0 : _c.filter((member) => member.account.id !== (careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount.id)).map((member) => {
                            var _a, _b;
                            return ({
                                id: member.id || 'id',
                                account: Object.assign({}, member.account),
                                name: `${member.account.firstName} ${member.account.lastName}` ||
                                    'name',
                                roles: member.roles || [],
                                fallback: `${(_b = (_a = member === null || member === void 0 ? void 0 : member.account) === null || _a === void 0 ? void 0 : _a.firstName) === null || _b === void 0 ? void 0 : _b[0]}`,
                            });
                        })) || [] })] }), _jsx(Dialog, Object.assign({ isOpen: isInviteOpen, setIsOpen: setIsInviteOpen }, { children: _jsx(CareCircleInvite, { onInvite: handleInvite, onCancel: () => setIsInviteOpen(false), relationshipOptions: relationshipOptions }) }))] }));
};
const Wrapper = styled.div `
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const Header = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledButton = styled(Button) `
  margin-bottom: ${(props) => props.theme.spacing[36]};
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: left;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const BackButton = styled.div `
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[20]};
`;
CareCircleMemberAll.displayName = 'CareCircleMemberAll';
export default CareCircleMemberAll;
