import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Button, Card, Icon, Input } from "../../atoms";
// tanstack
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable, } from '@tanstack/react-table';
/**
 * Table is a simple table component, using the tanstack react-table.
 */
export const Table = ({ className, columns, rows, actions, defaultAction, toolbarActions, noDataMsg = 'No data available', isSearchable = true, isHeader = true, isPaginated = true, isBorder = false, isMobile = false, isTotalRows = true, }) => {
    // state
    const [data, setData] = useState(() => [...(rows || [])]);
    const [filteredData, setFilteredData] = useState(() => [...data]);
    const [searchValue, setSearchValue] = useState('');
    const [{ pageIndex, pageSize }, setPagination] = useState({
        pageIndex: 0,
        pageSize: isPaginated ? 6 : 100000,
    });
    // memo
    const pagination = useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize]);
    const TableRow = useMemo(() => {
        return isMobile ? Card : 'tr';
    }, [isMobile]);
    // hooks/
    const table = useReactTable({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
    });
    // effects
    useEffect(() => {
        setData(rows || []);
    }, [rows]);
    useEffect(() => {
        if (!searchValue) {
            setFilteredData(data);
        }
        else {
            const filteredData = data.filter((row) => {
                return Object.keys(row).some((key) => {
                    return row[key]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.toLowerCase());
                });
            });
            setFilteredData(filteredData);
        }
    }, [data, searchValue]);
    return (_jsxs(TableContainer, Object.assign({ className: className, "$isBorder": isBorder }, { children: [isSearchable && (_jsxs(TableToolbar, Object.assign({ "$isMobile": isMobile }, { children: [_jsx(Input, { ariaLabel: 'search', placeholder: 'Search', value: searchValue, onChange: setSearchValue, iconLeft: 'search', size: 'sm' }), _jsx(ToolbarActions, { children: toolbarActions })] }))), _jsx(TableWrapper, Object.assign({ "$isMobile": isMobile }, { children: _jsxs(StyledTable, Object.assign({ "$isMobile": isMobile }, { children: [_jsx(Thead, Object.assign({ "$isMobile": isMobile }, { children: isHeader &&
                                table.getHeaderGroups().map((headerGroup) => (_jsxs("tr", { children: [headerGroup.headers.map((header) => (_jsx("th", { children: header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext()) }, header.id))), actions && actions.length > 0 && _jsx("th", {})] }, headerGroup.id))) })), _jsxs(Tbody, Object.assign({ "$isBorderBottom": isPaginated, "$isMobile": isMobile }, { children: [table.getRowModel().rows.length === 0 && (_jsx("tr", { children: _jsx("td", Object.assign({ colSpan: table.getHeaderGroups()[0].headers.length, style: { textAlign: 'center' } }, { children: noDataMsg })) })), table.getRowModel().rows.map((row) => {
                                    return (_jsxs(TableRow, Object.assign({ as: 'tr', color: 'none', style: { cursor: 'pointer' }, onClick: () => {
                                            var _a, _b;
                                            return !((_a = defaultAction === null || defaultAction === void 0 ? void 0 : defaultAction.isHidden) === null || _a === void 0 ? void 0 : _a.call(defaultAction, row.original)) &&
                                                ((_b = defaultAction === null || defaultAction === void 0 ? void 0 : defaultAction.onPress) === null || _b === void 0 ? void 0 : _b.call(defaultAction, row.original));
                                        } }, { children: [row.getVisibleCells().map((cell) => {
                                                let headerContext;
                                                table.getHeaderGroups().forEach((headerGroup) => {
                                                    headerGroup.headers.forEach((header) => {
                                                        if (header.column.id === cell.column.id) {
                                                            headerContext = header.getContext;
                                                        }
                                                    });
                                                });
                                                const headerContent = flexRender(cell.column.columnDef.header, headerContext());
                                                const valueContent = flexRender(cell.column.columnDef.cell, cell.getContext());
                                                return (_jsx("td", { children: _jsxs(Cell, Object.assign({ "$isMobile": isMobile }, { children: [isMobile && headerContent && (_jsx(MobileLabel, { children: headerContent })), _jsx(Value, Object.assign({ "$isMobile": isMobile }, { children: valueContent }))] })) }, cell.id));
                                            }), actions && actions.length > 0 && (_jsx("td", { children: _jsx(TableActions, Object.assign({ "$isMobile": isMobile }, { children: actions === null || actions === void 0 ? void 0 : actions.map((action, index) => !(action.isHidden && action.isHidden(row.original)) ? (_jsx(ActionButton, Object.assign({ iconLeft: action.iconLeft, ariaLabel: 'view', variant: action.buttonVariant || 'secondary', size: action.buttonSize || 'sm', onPress: () => action === null || action === void 0 ? void 0 : action.onPress(row.original), "$isMobile": isMobile }, { children: action.label }), index)) : null) })) }))] }), row.id));
                                })] })), _jsx(Tfoot, {})] })) })), isPaginated && (_jsxs(PaginationButtonGroup, { children: [_jsx(PaginationButton, Object.assign({ onClick: () => table.setPageIndex(0), "$isDisabled": !table.getCanPreviousPage() }, { children: _jsx(Icon, { icon: 'chevrons-left', fontSize: 16 }) })), _jsx(PaginationButton, Object.assign({ onClick: () => table.previousPage(), "$isDisabled": !table.getCanPreviousPage() }, { children: _jsx(Icon, { icon: 'chevron-left', fontSize: 16 }) })), Array(table.getPageCount())
                        .fill(0)
                        .map((_, i) => {
                        const page = i + 1;
                        return (_jsx(PaginationButton, Object.assign({ onClick: () => table.setPageIndex(i), "$isDisabled": table.getState().pagination.pageIndex === i }, { children: page }), i));
                    }), _jsx(PaginationButton, Object.assign({ onClick: () => table.getCanNextPage() && table.nextPage(), "$isDisabled": !table.getCanNextPage() }, { children: _jsx(Icon, { icon: 'chevron-right', fontSize: 16 }) })), _jsx(PaginationButton, Object.assign({ onClick: () => table.setPageIndex(table.getPageCount() - 1), "$isDisabled": !table.getCanNextPage() }, { children: _jsx(Icon, { icon: 'chevrons-right', fontSize: 16 }) }))] })), isTotalRows && (_jsxs("div", { children: [(filteredData === null || filteredData === void 0 ? void 0 : filteredData.length) || 0, " row", (filteredData === null || filteredData === void 0 ? void 0 : filteredData.length) !== 1 ? 's' : ''] }))] })));
};
const TableContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  background: ${(props) => props.theme.color.neutral[10]};
  border-radius: 4px;

  ${(props) => props.$isBorder &&
    css `
      border: 1px solid ${props.theme.color.neutral[60]};
      border-radius: 16px;
    `}
`;
const ToolbarActions = styled.div `
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing[8]};
`;
const TableToolbar = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
  background: ${(props) => props.theme.color.neutral[20]};
  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[16]};
  border-radius: 8px;

  ${(props) => props.$isMobile &&
    css `
      flex-direction: column;
    `}
`;
const TableWrapper = styled.div `
  overflow: auto;
  width: 100%;
  ${(props) => props.$isMobile &&
    css `
      overflow: hidden;
    `}
`;
const StyledTable = styled.table `
  padding: 0;
  border-spacing: 0;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;

  ${(props) => props.$isMobile &&
    css `
      table-layout: fixed;
      width: 100%;
      border-spacing: 0 ${props.theme.spacing[24]};
      margin: -${props.theme.spacing[16]} 0;
    `}
`;
const Thead = styled.thead `
  ${(props) => props.$isMobile &&
    css `
      display: none;
    `}

  & > tr > th {
    margin: 0;
    padding: ${(props) => props.theme.spacing[12]} 0;
    padding-right: ${(props) => props.theme.spacing[32]};
    font: ${(props) => props.theme.typography.body.sm.medium};
    color: ${(props) => props.theme.color.neutral[90]};
    text-align: left;
    border-bottom: 1px solid ${(props) => props.theme.color.neutral[60]};
  }
  & > tr > th:last-child {
    padding-right: 0;
  }
`;
const Tbody = styled.tbody `
  & > tr {
    max-width: 100%;
  }

  & > tr > td {
    ${(props) => props.$isMobile &&
    css `
        display: block;
      `}

    font: ${(props) => props.theme.typography.body.sm.regular};
    color: ${(props) => props.theme.color.neutral[80]};
    padding: ${(props) => props.theme.spacing[12]} 0;
    padding-right: ${(props) => props.theme.spacing[32]};

    ${(props) => props.$isBorderBottom &&
    css `
        border-bottom: 1px solid ${(props) => props.theme.color.neutral[50]};
      `}
    ${(props) => props.$isMobile &&
    css `
        border-bottom: none;
        padding: ${(props) => props.theme.spacing[8]} 0;
        border-left: 1px solid ${(props) => props.theme.color.neutral[50]};
        border-right: 1px solid ${(props) => props.theme.color.neutral[50]};
      `}

    ${(props) => props.$isMobile &&
    css `
        padding-left: ${props.theme.spacing[36]};
        padding-right: ${props.theme.spacing[36]};
      `}
  }
  & > tr > td:first-child {
    ${(props) => props.$isMobile &&
    css `
        padding-top: ${props.theme.spacing[36]};
        border-top: 1px solid ${(props) => props.theme.color.neutral[50]};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      `}
  }
  & > tr > td:last-child {
    padding-right: 0;

    ${(props) => props.$isMobile &&
    css `
        padding-right: ${props.theme.spacing[36]};
        padding-bottom: ${props.theme.spacing[36]};
        border-bottom: 1px solid ${(props) => props.theme.color.neutral[50]};
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      `}
  }
`;
const Cell = styled.div `
  ${(props) => props.$isMobile &&
    css `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: ${(props) => props.theme.spacing[8]};
      padding-bottom: ${(props) => props.theme.spacing[16]};
      border-bottom: 1px solid ${(props) => props.theme.color.neutral[40]};

      width: 100%;
    `}
`;
const MobileLabel = styled.div `
  font: ${(props) => props.theme.typography.body.sm.medium};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Value = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TableActions = styled.div `
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing[8]};

  ${(props) => props.$isMobile &&
    css `
      justify-content: center;
    `}
`;
const ActionButton = styled(Button) `
  ${(props) => props.$isMobile &&
    css `
      width: 100%;
      max-width: 300px;
    `}
`;
const Tfoot = styled.tfoot ``;
const PaginationButtonGroup = styled.div `
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing[2]};
  font: ${(props) => props.theme.typography.button.sm};
  padding: ${(props) => props.theme.spacing[16]};
  padding-top: 0;
`;
const PaginationButton = styled.button `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  color: ${(props) => props.theme.color.neutral[80]};
  cursor: pointer;
  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[40]};
    `}
`;
Table.displayName = 'Table';
export default Table;
